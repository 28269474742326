import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Modal,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "929px",
    maxWidth: "90vw",
    margin: 0,
    maxHeight: "880px",
  },
}));

const StyledDialogTitle = styled(DialogTitle)({
  backgroundColor: "#102A62",
  color: "#FFFFFF",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "20px",
  padding: "15px 0",
  textAlign: "center",
});

const StyledDialogActions = styled(DialogActions)({
  justifyContent: "center",
  padding: "7px 0 20px 0",
});

const StyledButton = styled(Button)({
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "12px",
  textTransform: "uppercase",
  padding: "13px 16px",
  width: "100px",
});

const PaymentPolicyList = ({
  open,
  onClose,
  headerData,
  checkedPolicies,
  setCheckedPolicies,
  tableCheckedPolicies,
  setTableCheckedPolicies,
}) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [manageItemsModal, setManageItemsModal] = useState(false);
  const [currentPolicy, setCurrentPolicy] = useState(null);
  const { t } = useTranslation();

  const handleSearchKeywordChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const handleCheckboxChange = (header) => {
    if (checkedPolicies.includes(header)) {
      // Trying to uncheck, open confirmation modal
      setCurrentPolicy(header);
      setManageItemsModal(true);
    } else {
      // Directly add the header to the checked list
      setCheckedPolicies((prevSelected) => [...prevSelected, header]);
    }
  };

  const confirmUncheck = () => {
    setCheckedPolicies((prevSelected) =>
      prevSelected.filter((item) => item !== currentPolicy)
    );
    setTableCheckedPolicies((prevSelected) =>
      prevSelected.filter((item) => item !== currentPolicy)
    );
    setManageItemsModal(false);
    setCurrentPolicy(null);
  };

  const cancelUncheck = () => {
    setManageItemsModal(false);
    setCurrentPolicy(null);
  };

  const filteredHeaderData = headerData.filter((header) =>
    header.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  return (
    <>
      <StyledDialog open={open} onClose={onClose}>
        <StyledDialogTitle>
          {t("paymentPolicyLimits.modalTitle")}
        </StyledDialogTitle>
        <DialogContent>
          {/* Filter Section */}
          <Box
            sx={{
              background: "#EFEFEF",
              borderRadius: "5px",
              padding: "1rem",
              marginTop: "15px",
              marginBottom: "20px",
            }}
          >
            <Typography sx={{ marginBottom: "10px" }}>Filters</Typography>
            <TextField
              name="descriptionFilter"
              label={t("paymentPolicyLimits.description")}
              value={searchKeyword}
              onChange={handleSearchKeywordChange}
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "7px",
                  minHeight: "50px",
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.2em",
                },
              }}
              InputProps={{
                style: { height: "56px" },
              }}
            />
          </Box>

          {/* List of Headers */}
          <Box sx={{ maxHeight: "560px", overflowY: "auto" }}>
            {filteredHeaderData.map((header, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={checkedPolicies.includes(header)}
                    onChange={() => handleCheckboxChange(header)}
                    sx={{
                      "&.Mui-checked": {
                        color: "#004F9F", // Optional: Text color when checked
                      },
                    }}
                  />
                }
                label={header}
                sx={{
                  backgroundColor: "#EFEFEF",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  fontSize: "16px",
                  borderRadius: "10px",
                  height: "52px",
                  ml: "2px",
                }}
              />
            ))}
          </Box>
        </DialogContent>
        <StyledDialogActions>
          <StyledButton
            variant="contained"
            onClick={onClose}
            style={{ backgroundColor: "#737D86", color: "#F8F8F8" }}
          >
            {t("paymentPolicyLimits.close")}
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>

      {/* Confirmation Modal */}
      <Modal open={manageItemsModal} onClose={cancelUncheck}>
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "560px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
              color: "#fff",
            }}
          >
            {t("paymentPolicyLimits.removeItemConfirm")}
          </Box>

          <Box sx={{ p: 2 }}>
            <Typography
              variant="body2"
              sx={{ whiteSpace: "pre-wrap", width: "550px", overflowX: "auto" }}
            >
              {t("paymentPolicyLimits.removeMessage")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "60px",
              pt: 4,
            }}
          >
            <Button
              onClick={cancelUncheck}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              {t("no")}
            </Button>

            <Button
              onClick={confirmUncheck}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default PaymentPolicyList;
