import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Modal,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { useDropzone } from "react-dropzone";

import Layout from "components/structure/Layout";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import { Height, Label } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import RedSwitch from "utils/RedSwitch";
import arrowLeft from "assets/arrowLeft.svg";
import save from "assets/SaveNew.svg";
import { occurrenceTypeSchema } from "utils/validations";
import { SnackbarContext } from "context/snackBarContext";
import OccurrenceTypeService from "services/OccurrenceTypeService";

import ByteSize_Upload from "assets/bytesize_upload.svg";

import Climate_Icon from "assets/climate_icon.svg";
import Column from "utils/Column";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import { getBase64 } from "../../../../utils/CommonFunction";
import UserService from "services/UserService";
import { now } from "moment";
import ExpandMoreIcon from "@mui/icons-material/ArrowDropDown";

let staticFiles = [];

const AddFieldOccurence = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const {
    occurrenceTypeDescription,
    status,
    edit,
    occurrenceId,
    occurrenceCategory,
    occurrenceOnField,
    occurrenceOnSeedBed,
    occurrenceImage,
  } = location.state || {};
  console.log("occurrenceImage", occurrenceImage);

  const [values, setValues] = useState({
    occurrenceDescription: occurrenceTypeDescription || "",
    occurrenceCategory: occurrenceCategory || "",
    status: status === true ? true : false,
    onField: occurrenceOnField,
    seedbed: occurrenceOnSeedBed,
    imageLink: occurrenceImage,
  });

  const [climateValues, setClimateValues] = useState([location.state]);
  console.log("climateValues", climateValues);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [deletedFunctionalities, setDeletedFunctionalities] = useState([]);

  const [categoryType, setCategoryType] = useState("");
  console.log("categoryType", categoryType);
  const [inputValue, setInputValue] = useState(false);
  const [seedBedValue, setSeedbed] = useState(false);
  const [onFieldValue, setOnField] = useState(false);
  const [User, setUser] = useState("");

  const [isOptionHigh, setOptionHigh] = useState(false);
  const [isOptionMedium, setOptionMedium] = useState(false);
  const [isOptionLow, setOptioLow] = useState(false);

  const [boxes, setBoxes] = useState([]);

  const inputFileRef = React.useRef();
  const [files, setfiles] = React.useState([]);
  const [imageFile, setImageFile] = useState();
  const [newMode, setNewMode] = useState(true);

  const [percentage, setPercentage] = React.useState(0);
  const [imageError, setImageError] = useState("");
  const [imageName, setImageName] = useState("");
  const [imageBase64, setImageBase64] = useState("");

  const onFileChangeCapture = (e) => {
    /*Selected files data can be collected here.*/
    console.log(e.target.files);
  };
  const onBtnClick = () => {
    setImageError("");
    /*Collecting node-element and performing click*/
    if (edit != false) {
      inputFileRef.current.click();
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    console.log("on drop", acceptedFiles);
    // Process the files (for example, upload them or display a preview)
    setImageError("");
    const pngFiles = acceptedFiles.filter((file) => file.type === "image/png");

    if (pngFiles.length === 0) {
      setImageError("Only PNG files are supported.");
      return;
    }
    pngFiles.forEach((file) => {
      console.log("event.target.files[0]::", file);
      handleImageDelete();
      setImageName(file.name);
      setImageFile(URL.createObjectURL(file));
      getBase64(file)
        .then((res) => {
          const new_file = {
            // country: country.length > 0 ? country[0].countryName : null,
            fileContent: res,
            fileName: file.name,
            mimeType: file.type,
            batOneId: "",
          };
          handleUpload(new_file);
        })
        .catch((err) => console.log(err));
      // const reader = new FileReader();
      // reader.onload = () => {
      //   const fileUrl = reader.result;
      //   console.log("Image uploaded successfully: ", fileUrl);
      //   console.log('event.target.files[0]::', reader);

      //   // Do something with the file URL (e.g., set it in the state to display the image)
      // };
      // reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });
  console.log("boxes", boxes);
  const handleAddOptions = () => {
    // console.log("handleadd")
    var options = "";
    if (boxes.length == 0) {
      options = "High";
      setOptionHigh(true);
    } else if (boxes.length == 1) {
      options = "Medium";
      setOptionMedium(true);
    } else if (boxes.length == 2) {
      options = "Low";
      setOptioLow(true);
    }

    if (boxes.length < 3) {
      setBoxes([
        ...boxes,
        { id: boxes.length, text: options, isDeleted: false },
      ]);
    }
  };

  const handleDeleteOptions = (index) => {
    let count = 0;
    for (let box of boxes) {
      if (count === index) {
        box["isDeleted"] = true;
        setDeletedFunctionalities([...deletedFunctionalities, box]);
      }
      count += 1;
    }
    if (index == 0) {
      setOptionHigh(false);
    } else if (index == 1) {
      setOptionMedium(false);
    } else if (index == 2) {
      setOptioLow(false);
    }

    setBoxes(boxes.filter((box, i) => i !== index));
    // handleClose();
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (event.target.name == "occurrenceCategory") {
      setCategoryType(event.target.value);
    }

    if (event.target.name == "seedbed") {
      setSeedbed(event.target.value);
    }

    if (event.target.name == "onField") {
      setOnField(event.target.value);
    }
  };

  const handleChangeClimateData = (event) => {
    const { name, value, type, checked } = event.target;
    console.log("name::", name, checked);
    setClimateValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
    if (type === "checkbox") {
      if (checked == false) {
        setBoxes([]);
      }
    }
    if (event.target.name == "inputValue") {
      setInputValue(checked);
    }
  };

  const handleClear = (field) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: "",
    }));
    setCategoryType("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let schemaValidationErrors = {};
    try {
      await occurrenceTypeSchema.validate(values, { abortEarly: false });
    } catch (err) {
      err?.inner?.forEach((error) => {
        schemaValidationErrors[error.path] = error.message;
      });
      setErrors(schemaValidationErrors);
    }

    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        createdByUser: "test user",
        modifiedByUser: "test user",
        occurrenceTypeDescription: values.occurrenceDescription,
        occurrenceCategory: values.occurrenceCategory,
        occurrenceOnField: values.onField,
        occurrenceOnSeedBed: values.seedbed,
        occurrenceImage: files.length > 0 ? files[0].fileUrl : "",
        occurrenceLocation: "string",
        occurrenceInput: climateValues.inputValue,
        optionHigh: isOptionHigh,
        optionMedium: isOptionMedium,
        optionLow: isOptionLow,
        status: values.status || false,
        countryCode: "BRA",
        isDelete: false,
        createdDateTimeUTC: new Date().toISOString(),
        modifiedDateTimeUTC: new Date().toISOString(),
      };
      try {
        setLoading(true);
        const response = await OccurrenceTypeService.createOccurrence({
          ...payload,
        });
        if (response.status === 200) {
          setSeverity("success");
          setMessage("Added successfully");
          setOpenAlert(true);
          navigate("/occurrenceType");
          setLoading(false);
        }
      } catch (error) {
        console.log("error:", error);
        setLoading(false);
        setSeverity("error");
        setMessage(error?.response?.data?.message);
        setOpenAlert(true);
        // }
      }
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    let schemaValidationErrors = {};
    try {
      await occurrenceTypeSchema.validate(values, { abortEarly: false });
    } catch (err) {
      err?.inner?.forEach((error) => {
        schemaValidationErrors[error.path] = error.message;
      });
      setErrors(schemaValidationErrors);
    }
    if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        occurrenceId: occurrenceId,
        createdByUser: "Test user",
        modifiedByUser: "Test user",
        occurrenceTypeDescription: values.occurrenceDescription,
        occurrenceCategory: values.occurrenceCategory,
        occurrenceOnField: values.onField,
        occurrenceOnSeedBed: values.seedbed,
        occurrenceImage: files.length > 0 ? files[0].fileUrl : "",
        occurrenceLocation: "string",
        occurrenceInput: climateValues.inputValue,
        optionHigh: isOptionHigh,
        optionMedium: isOptionMedium,
        optionLow: isOptionLow,
        status: values.status || false,
        countryCode: "BRA",
        isDelete: false,
        modifiedDateTimeUTC: new Date().toISOString(),
      };
      try {
        setLoading(true);
        const response = await OccurrenceTypeService.updateOccurrence({
          ...payload,
        });
        setLoading(false);
        if (response.status === 200) {
          setSeverity("success");
          setMessage("Updated successfully");
          setOpenAlert(true);
          navigate("/occurrenceType");
        }
      } catch (error) {
        setLoading(false);
        if (error.response) {
          const errorMessage = error.response.data.message;
          setMessage(errorMessage);
          setOpenAlert(true);
        } else {
          console.error("Failed to submit form");
          setSeverity("error");
          setMessage("Update failed");
          setOpenAlert(true);
        }
      }
    }
  };

  const handleFileUpload = (event) => {
    event.preventDefault();
    setImageError("");

    const file = event.target.files[0];
    if (file.type === "image/png") {
      handleImageDelete();
      setImageFile(URL.createObjectURL(event.target.files[0]));
      setImageName(file.name);
      getBase64(file)
        .then((res) => {
          const new_file = {
            // country: country.length > 0 ? country[0].countryName : null,
            fileContent: res,
            fileName: file.name,
            mimeType: file.type,
            batOneId: "",
          };
          handleUpload(new_file);
        })
        .catch((err) => console.log(err));
    } else {
      setImageError("Only PNG files are supported.");
    }
    // let c_code = formValues.countryCode.value;
    // let country = countries.filter((ele) => ele.countryCode == c_code);
  };

  const config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percent = Math.floor((loaded * 100) / total);
      if (percent <= 100) {
        setPercentage(percent); // hook to set the value of current level that needs to be passed to the progressbar
      }
    },
  };

  const handleUpload = (file) => {
    setLoading(true);
    UserService.uploadUserFile(file, config)
      .then((response) => {
        let fileData = {
          fileUrl: response.data.response[file.fileName],
          fileName: file.fileName,
          country: "BRA",
          mimeType: file.mimeType,
        };
        // setfiles((olddata) => [...olddata, fileData]);
        setfiles([fileData]);
        staticFiles.push(fileData);
        setPercentage(0);
        setLoading(false);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setOpenAlert(true);
        setSeverity("error");
        setTimeout(() => {
          setPercentage(0);
        }, 1000);
        setLoading(false);
        // let fileInput = document.getElementById("contained-button-file");
        // fileInput.value = "";
      });
  };

  // const onDeleteFile = (ele, existing) => {
  //   setLoading(true);
  //   let query = `?fileUrl=${ele.fileUrl}`;
  //   if (existing) query = query + `&fileUid=${ele?.fileUid}`;

  //   UserService.deleteUserFile(query)
  //     .then((response) => {
  //       if (existing) {
  //         let new_user = JSON.parse(JSON.stringify(User));
  //         let newfiles = new_user.userAttachmentsDtoList.filter(
  //           (file) => file.fileUid != ele.fileUid
  //         );
  //         new_user.userAttachmentsDtoList = newfiles;
  //         setUser(new_user);
  //       } else {
  //         let newfiles = files.filter((file) => file !== ele);
  //         setfiles(newfiles);
  //         staticFiles.push(newfiles);
  //       }
  //       setLoading(false);
  //       setMessage("deleted successfully.");
  //       setOpenAlert(true);
  //       setSeverity("success");
  //     })
  //     .catch((error) => {
  //       setMessage(error?.message);
  //       setOpenAlert(true);
  //       setSeverity("error");
  //       setLoading(false);
  //     });
  // };

  const handleCancelClick = () => {
    if (edit != false) {
      setOpen(true); // Open the confirmation modal
    } else {
      navigate("/occurrenceType");
    }
  };

  const handleClose = () => {
    setOpen(false); // Close the modal without deleting
  };

  useEffect(() => {
    // let length = 0;
    if (edit != undefined) {
      console.log("location?.state::", location?.state);
      setImageFile(location?.state?.occurrenceImage);
      setClimateValues((prevValues) => ({
        ...prevValues,
        ["inputValue"]: location?.state?.occurrenceInput,
      }));
      setInputValue(location?.state?.occurrenceInput);
      setNewMode(false);
      const newBoxes = [];
      if (location?.state?.optionHigh) {
        newBoxes.push({ id: 0, text: "High", isDeleted: false });
      }
      if (location.state.optionMedium) {
        newBoxes.push({
          id: newBoxes.length,
          text: "Medium",
          isDeleted: false,
        });
      }
      if (location.state.optionLow) {
        newBoxes.push({ id: newBoxes.length, text: "Low", isDeleted: false });
      }
      setBoxes(newBoxes);
    }

    if (occurrenceCategory !== "string") {
      setCategoryType(occurrenceCategory);
    }
    return () => { };
  }, [!files]);
  useEffect(() => {
    setCategoryType(values.occurrenceCategory);
    if (occurrenceImage != "" && occurrenceImage != undefined && occurrenceImage!= null) {
      console.log("OCCIMAGAE", typeof occurrenceImage)
      callImageBase64Api();
    }
  }, []);
  const handleImageDelete = () => {
    setfiles([]);
    setImageFile();
    setImageName();
    setImageError("");
    setValues((prevValues) => ({
      ...prevValues,
      imageLink: "",
    }));
  };
  const callImageBase64Api = () => {
    OccurrenceTypeService.getBase64Image(occurrenceImage)
      .then((response) => {
        setImageBase64(response.data.response.fileContent);
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          setMessage(errorMessage);
          setOpenAlert(true);
        } else {
          setSeverity("error");
          setMessage("Failed to retrieve image.");
          setOpenAlert(true);
        }
      });
  };

  return (
    <Layout title={"Occurrence Type Registration"} background="#ffffff">
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        style={{ paddingBottom: "130px" }}
      >
        <Box display="" alignItems="center">
          <PaperContainer background="#EFEFEF">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "10px",
                marginTop: "-20px",
                marginLeft: "-5px",
              }}
            >
              <ExpandMoreIcon sx={{ color: "#000" }} />
              <Typography sx={{ fontSize: "14px !important" }}>Form</Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    name="occurrenceDescription"
                    label="Field Occurrence Type Description"
                    required
                    disabled={!newMode && !edit}
                    error={!!errors.occurrenceDescription}
                    helperText={errors.occurrenceDescription}
                    value={values.occurrenceDescription}
                    onChange={handleChange}
                    sx={{
                      width: "100%",
                      // height: "46px",
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-disabled": {
                          "& fieldset": {
                            borderColor: "gray",
                            borderWidth: "1px",
                          },
                          "&:hover fieldset": {
                            borderColor: "gray",
                            borderWidth: "1px",
                          },
                        },
                      },
                      "& .MuiInputLabel-root": {
                        "&.Mui-disabled": {
                          color: "#102a62",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      disabled={!newMode && !edit}
                      select
                      required
                      name="occurrenceCategory"
                      label={"Occurrence Category"}
                      error={!!errors.occurrenceCategory}
                      helperText={errors.occurrenceCategory}
                      value={values.occurrenceCategory || "PL"}
                      onChange={handleChange}
                      variant="outlined"
                      sx={{
                        // height: "46px",
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-disabled": {
                            "& fieldset": {
                              borderColor: "gray",
                              borderWidth: "1px",
                            },
                            "&:hover fieldset": {
                              borderColor: "gray",
                              borderWidth: "1px",
                            },
                          },
                        },
                        "& .MuiInputLabel-root": {
                          "&.Mui-disabled": {
                            color: "#102a62",
                          },
                        },
                      }}
                      SelectProps={{
                        IconComponent: ArrowDropDownIcon,
                        endAdornment: values?.occurrenceCategory && (
                          <CloseIcon
                            onClick={() => {
                              if (newMode || edit) {
                                handleClear("occurrenceCategory");
                              }
                            }}
                            style={{
                              width: "18px",
                              cursor: newMode || edit ? "pointer" : "default",
                              marginRight: "12px",
                            }}
                          />
                        ),
                      }}
                    >
                      <MenuItem value={"PL"} disabled sx={{ display: "none" }}>
                        Select an option
                      </MenuItem>
                      <MenuItem
                        value={"Pest"}
                        sx={{
                          backgroundColor:
                            "Pest" === values.occurrenceCategory
                              ? "#D3D3D3 !important"
                              : "transparent !important",
                          "&:hover": {
                            backgroundColor: "#D3D3D3 !important",
                          },
                        }}
                      >
                        {" "}
                        Pest{" "}
                      </MenuItem>
                      <MenuItem
                        value={"Disease"}
                        sx={{
                          backgroundColor:
                            "Disease" === values.occurrenceCategory
                              ? "#D3D3D3 !important"
                              : "transparent !important",
                          "&:hover": {
                            backgroundColor: "#D3D3D3 !important",
                          },
                        }}
                      >
                        {" "}
                        Disease{" "}
                      </MenuItem>
                      <MenuItem
                        value={"Climate"}
                        sx={{
                          backgroundColor:
                            "Climate" === values.occurrenceCategory
                              ? "#D3D3D3 !important"
                              : "transparent !important",
                          "&:hover": {
                            backgroundColor: "#D3D3D3 !important",
                          },
                        }}
                      >
                        {" "}
                        Climate{" "}
                      </MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>

                <Box
                  sx={{
                    ml: "15px",
                    mt: "15px",
                    display: "flex",
                    // alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <FormControl component="fieldset">
                    <FormLabel
                      sx={{
                        color: "#5A646E !important",
                        mb: 1,
                        ml: 0.5,
                        fontWeight: 500,
                        "&.Mui-focused": { color: "#5A646E" },
                        "&:hover": { color: "#5A646E" },
                        fontWeight: "500",
                      }}
                    >
                      Status
                    </FormLabel>

                    <FormControlLabel
                      sx={{
                        ml: 0.5,
                        display: "flex",
                        alignItems: "center",
                        gap: "0.4rem",
                      }}
                      control={
                        <RedSwitch
                          disabled={!newMode && !edit}
                          checked={values.status}
                          onChange={handleChange}
                          name="status"
                          color="primary"
                        />
                      }
                      labelPlacement="start"
                      label={
                        <Box sx={{ width: "3rem", mr: "5px", fontWeight: "500", color: "#737D86" }}>
                          {values?.status === true ? "Active" : "Inactive"}
                        </Box>
                      }
                    />
                    {/* 
                    <FormLabel
                      sx={{
                        ml: 4,
                        mb: 0,
                        display: "flex",
                      }}
                    >
                      Active
                    </FormLabel> */}
                  </FormControl>
                </Box>
              </Grid>
            </Box>

            {(categoryType === "Disease" || categoryType === "Pest") && (
              <>
                <FormControl sx={{ ml: 5, mt: 2 }}>
                  <Typography>Occures on Seedbed?</Typography>

                  <Box display={"flex"}>
                    <FormLabel
                      sx={{
                        ml: 4,
                        mb: 0,
                        display: "flex",
                        color: "#000 !important",
                        // alignItems: "center",
                        // gap: "0.4rem",
                      }}
                    >
                      No
                    </FormLabel>

                    <FormControlLabel
                      sx={{
                        ml: 2,
                        mb: 0,
                        display: "flex",
                        color: "#000 !important",
                        // alignItems: "center",
                        // gap: "0.4rem",
                      }}
                      control={
                        <RedSwitch
                          disabled={!newMode && !edit}
                          checked={values.seedbed}
                          onChange={handleChange}
                          name="seedbed"
                          color="primary"
                        // disabled={details}
                        />
                      }
                      // label={
                      //   <Box sx={{ width: "3rem" }}>
                      //     {"Active"}
                      //   </Box>
                      // }
                      labelPlacement="start"
                    />

                    <FormLabel
                      sx={{
                        ml: 4,
                        mb: 0,
                        display: "flex",
                        color: "#000 !important",
                        // alignItems: "center",
                        // gap: "0.4rem",
                      }}
                    >
                      Yes
                    </FormLabel>
                  </Box>
                </FormControl>

                <FormControl sx={{ ml: 5, mt: 2 }}>
                  <Typography>Occures on Field?</Typography>

                  <Box display={"flex"}>
                    <FormLabel
                      sx={{
                        ml: 4,
                        mb: 0,
                        display: "flex",
                        color: "#000 !important",
                        // alignItems: "center",
                        // gap: "0.4rem",
                      }}
                    >
                      No
                    </FormLabel>

                    <FormControlLabel
                      sx={{
                        ml: 2,
                        mb: 0,
                        display: "flex",
                        // alignItems: "center",
                        // gap: "0.4rem",
                      }}
                      control={
                        <RedSwitch
                          disabled={!newMode && !edit}
                          checked={values.onField}
                          onChange={handleChange}
                          name="onField"
                          color="primary"
                        // disabled={details}
                        />
                      }
                      // label={
                      //   <Box sx={{ width: "3rem" }}>
                      //     {"Active"}
                      //   </Box>
                      // }
                      labelPlacement="start"
                    />

                    <FormLabel
                      sx={{
                        ml: 4,
                        mb: 0,
                        display: "flex",
                        color: "#000 !important",
                        // alignItems: "center",
                        // gap: "0.4rem",
                      }}
                    >
                      Yes
                    </FormLabel>
                  </Box>
                </FormControl>
              </>
            )}

            {categoryType === "Climate" && (
              <>
                <FormControl sx={{ ml: 1, mt: 1 }}>
                  <Typography>Input Type</Typography>

                  <Box display={"flex"}>
                    <FormLabel
                      sx={{
                        ml: 2,
                        color: "#000 !important",
                      }}
                    >
                      Input Value
                    </FormLabel>

                    <FormControlLabel
                      sx={{
                        ml: 2,
                      }}
                      control={
                        <RedSwitch
                          disabled={!newMode && !edit}
                          checked={climateValues.inputValue}
                          onChange={handleChangeClimateData}
                          name="inputValue"
                        // color="primary"
                        // disabled={details}
                        />
                      }
                      labelPlacement="start"
                    />

                    <FormLabel
                      sx={{
                        ml: 3,
                        color: "#000 !important",
                      }}
                    >
                      Select Option
                    </FormLabel>
                  </Box>
                </FormControl>

                <FormControl sx={{ ml: 5, mt: 1 }}>
                  <Typography>Upload Climate Icon</Typography>

                  <input
                    style={{ display: "none" }}
                    ref={inputFileRef}
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    onChangeCapture={onFileChangeCapture}
                    onChange={(e) => handleFileUpload(e)}
                  />
                  {/* <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "top",
                      width: "100%",
                      maxWidth: "1050px",
                      backgroundColor: "blue",
                    }}
                  > */}

                  {console.log("dataComing", files.length, values?.imageLink)}
                  {files.length === 0 &&
                    (values?.imageLink === undefined ||
                      values?.imageLink === "") ? (
                    <Box
                      onClick={onBtnClick}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "200px",
                        height: "80px",
                        border: " 0.62px dashed",
                        borderColor: isDragActive ? "blue" : "#ccc",
                        borderRadius: "8px",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "#E9EBEE",
                        padding: "0.5rem",
                      }}
                      {...(edit !== false ? getRootProps() : {})}
                    >
                      {edit !== false && <input {...getInputProps()} />}
                      <img
                        src={ByteSize_Upload}
                        style={{
                          height: "50px",
                          color: isDragActive ? "blue" : "red",
                        }}
                        alt="No Data"
                      />
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "13px !important",
                          color: isDragActive ? "blue" : "black",
                        }}
                      >
                        {/* <div  */}
                        {/* {...(edit !== false ? getRootProps() : {})} */}
                        {/* > */}
                        {/* {edit !== false && <input {...getInputProps()} />} */}
                        {/* {isDragActive ? (
                                <p
                                  style={{
                                    color: "blue",
                                    fontSize: "5px !important",
                                  }}
                                >
                                  Drop the image here ...
                                </p>
                              ) : ( */}
                        <div>
                          Drag and Drop
                          <br />
                          your PNG file here
                        </div>
                        {/* )} */}
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "inline-block",
                          position: "relative",
                          padding: "0.5rem",
                        }}
                      >
                        <Box
                          sx={{
                            display: "inline-block",
                            position: "relative",
                          }}
                        >
                          {values?.imageLink && imageBase64 != "" ? (
                            <img
                              src={`data:image/jpeg;base64,${imageBase64}`}
                              style={{
                                maxWidth: "200px",
                                maxHeight: "80px",
                                width: "auto",
                                height: "auto",
                                objectFit: "contain",
                              }}
                              alt="Image from link"
                            />
                          ) : (
                            <img
                              src={imageFile}
                              style={{
                                maxWidth: "200px",
                                maxHeight: "80px",
                                width: "auto",
                                height: "auto",
                                objectFit: "contain",
                              }}
                              alt="No Data"
                            />
                          )}
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation();
                              handleImageDelete();
                            }}
                            sx={{
                              position: "absolute",
                              top: "-10px",
                              right: "-10px",
                              backgroundColor: "white",
                              zIndex: 1,
                              padding: "0 !important",
                            }}
                            disabled={!newMode && !edit}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>
                    </>
                  )}
                  {/* </Box> */}
                  {imageError ? (
                    <Typography
                      color="error"
                      sx={{
                        mt: 1,
                      }}
                    >
                      {imageError}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        mt: 1,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "220px",
                      }}
                    >
                      {
                        values?.imageLink
                          ? decodeURIComponent(values?.imageLink.match(/_([^_]+)$/)?.[1] || "")
                          : imageName
                      }
                    </Typography>
                  )}
                  {/* </Box> */}
                </FormControl>

                <input
                  style={{ display: "none" }}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  ref={inputFileRef}
                  onChange={(e) => handleFileUpload(e)}
                  onChangeCapture={onFileChangeCapture}
                />

                <Button
                  style={{
                    height: "40px",
                    // width: "130px",
                    bottom: 0,
                    right: 0,
                    marginLeft: "40px",
                    marginTop: "50px",
                    color: "white",
                    background: edit != false ? "#004F9F" : "gray",
                    alignSelf: "center",
                  }}
                  onClick={onBtnClick}
                  disabled={!newMode && !edit}
                  variant="contained"
                >
                  {files.length > 0 || values?.imageLink
                    ? "SELECT NEW FILE"
                    : "SELECT FILE"}
                </Button>
              </>
            )}
          </PaperContainer>

          <Box>
            {console.log("valuesIn", inputValue)}
            {inputValue === true && (
              <>
                <Box
                  display=""
                  alignItems="center"
                  flexDirection={"column"}
                  mt={3}
                  mb={3}
                >
                  <Box display="flex" alignItems="center" flexDirection={"row"}>
                    <FormLabel
                      sx={{
                        ml: 1,
                        color: "#000000",
                        display: "flex",
                      }}
                    >
                      Available Options
                    </FormLabel>

                    <Button
                      style={{
                        position: "absolute",
                        right: 0,
                        float: "right",
                        marginLeft: "100px",
                        marginRight: "25px",
                        marginBottom: "8px",
                        color: "white",
                        background: edit != false ? "#004F9F" : "gray",
                        alignSelf: "center",
                      }}
                      onClick={handleAddOptions}
                      disabled={!newMode && !edit}
                      variant="contained"
                    >
                      ADD NEW
                    </Button>
                  </Box>

                  <hr
                    style={{
                      color: "#004F9F",
                      backgroundColor: "#004F9F",
                      height: 1,
                      borderColor: "#004F9F",
                    }}
                  />
                </Box>
              </>
            )}

            {boxes.map((box, index) => (
              <PaperContainer background="#EFEFEF">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  height={"15px"}
                >
                  <TextField
                    // select
                    name="optionsValue"
                    label="Option"
                    required
                    // error={!!errors.occurrenceDescription}
                    // helperText={errors.occurrenceDescription}
                    value={boxes[index].text}
                    // onChange={handleChangeClimateData}
                    sx={{
                      width: "530px",
                      height: "20px",
                      marginBottom: "30px",
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-disabled": {
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 0)",
                          },
                          "&:hover fieldset": {
                            borderColor: "rgba(0, 0, 0, 0)",
                          },
                        },
                      },
                    }}
                    disabled={!newMode && !edit}
                  />

                  <Button
                    disabled={!newMode && !edit}
                    // style={{ marginTop: "20px", marginRight: "5px" }}
                    onClick={() => handleDeleteOptions(index)}
                  >
                    <div>
                      <img src={_ButtonDelete_} alt="Delete" />
                    </div>
                  </Button>
                </Box>
              </PaperContainer>
            ))}
          </Box>

          <Box>
            <Button
              onClick={handleCancelClick}
              style={{
                height: "45px",
                position: "absolute",
                bottom: 0,
                left: 0,
                float: "left",
                marginLeft: "20px",
                marginBottom: "70px",
                background: "#737D86",
              }}
              startIcon={<img src={arrowLeft} alt="Arrow" />}
              variant="contained"
            >
              Cancel
            </Button>

            {edit != false && (
              <Button
                style={{
                  height: "45px",
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  float: "right",
                  marginRight: "20px",
                  marginBottom: "70px",
                  background: "#EF7D00",
                }}
                // disabled={!edit}
                type="submit"
                onClick={!edit ? handleSubmit : handleUpdate}
                startIcon={<img src={save} alt="Arrow" />}
                variant="contained"
              >
                {/* {!edit ? "Save " : "Update "} */}
                {"Save"}
              </Button>
            )}
            {/* )} */}
          </Box>
        </Box>
      </form>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "520px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              Cancel Operation
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
              Are you sure you want to cancel this operation? All your data will
              be lost.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              No
            </Button>
            <Button
              onClick={() => navigate("/occurrenceType")}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </Layout>
  );
};

const PaperContainer = styled(Paper)`
  padding: 2rem 1rem 1rem 1rem;
  margin: 12px 0px 0px 0px;
  background: ${(props) =>
    props.background || props.theme.palette.background.default};
`;

export default AddFieldOccurence;
