// GetLocalityConfiguration.jsx
import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Autocomplete,
  Checkbox,
  InputAdornment,
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
  TableContainer,
} from "@mui/material";
import Layout from "components/structure/Layout";
import { SnackbarContext } from "context/snackBarContext";
import LocalityConfigurationTable from "./CityConfigurationTable";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import CityConfigurationService from "services/CityConfigurationService";
import debounce from "lodash/debounce";
import Lable from "utils/lables.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { set } from "date-fns";
import { se } from "date-fns/locale";
import label from "../growingLabel.json";
import { useTranslation } from "react-i18next";
import iconSearch from "assets/iconSearchs.svg"

const GetLocalityConfiguration = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [menu, setMenu] = useState([]);
  const [postalCodes, setPostalCodes] = useState([]);
  const [postalCode, setPostalCode] = useState("");
  const [regionOptions, setRegionOptions] = useState("");
  const [region, setRegion] = useState("");
  const [city, setCity] = useState("");
  const [cityOptions, setCityOptions] = useState([]);
  const [showUsedRecords, setShowUsedRecords] = useState(false);

  const debouncedSearch = useCallback(
    debounce((value) => {
      fetchData(0, itemsPerPage, value);
    }, 10),
    [itemsPerPage]
  );

  const handleRegionChange = (event, newValue) => {
    setRegion(newValue);
    fetchRegions();
    if (newValue) {
      fetchData(0, itemsPerPage, searchKeyword, postalCode, newValue, city);
    } else {
      resetFilters();
    }
  };

  const handleCityChange = (event, newValue) => {
    setCity(newValue);
    fetchData(0, itemsPerPage, searchKeyword, postalCode, region, newValue);
  };

  useEffect(() => {
    fetchPostalCodes();
    fetchCities();
    fetchRegions();
    fetchData(currentPage, itemsPerPage, searchKeyword);
    if (currentPage === 0) {
      fetchMenuData();
    }
    return () => {
      debouncedSearch.cancel(); // Clean up on unmount
    };
  }, [currentPage, itemsPerPage]);

  const fetchCities = async () => {
    try {
      const res = await CityConfigurationService.getCityAPI();

      const cityNames = res.data.response.map((city) => city.cityName);

      setCityOptions(cityNames);
      console.log("respp::", res);
    } catch (error) {
      // Handle error
      setMessage("Error fetching cities");
      setSeverity("error");
      setOpenAlert(true);
    }
  };

  const fetchRegions = async () => {
    try {
      const res = await CityConfigurationService.getRegionAPI();
      const regionNames = res.data.response.map((region) => region.stateCode);
      setRegionOptions(regionNames);
    } catch (error) {
      // Handle error
      setMessage("Error fetching regions");
      setSeverity("error");
      setOpenAlert(true);
    }
  };

  const handleSearchChange = (value) => {
    setSearchKeyword(value);
    if (value !== "") {
      debouncedSearch(value);
    } else {
      resetFilters();
    }
  };

  const debouncedPostalCodeSearch = useCallback(
    debounce((value) => {
      fetchPostalCodes(0, 50, searchKeyword, value); // Update with the proper pagination if needed
    }, 10),
    []
  );

  const handlePostalCodeSearchChange = (event, newValue) => {
    if (newValue && newValue.length > 8) {
      setMessage(t("cityConfiguration.postalcodeval"));
      setSeverity("warning");
      setOpenAlert(true);
      return;
    }

    setPostalCode(newValue);

    if (!newValue) {
      resetFilters();
    } else {
      debouncedPostalCodeSearch(newValue);
      // Fetch data immediately if postal code is selected
      fetchData(0, itemsPerPage, searchKeyword, newValue, region, city);
    }
  };

  const resetFilters = () => {
    setSearchKeyword("");
    setPostalCode("");
    setRegion(""); // Reset region
    setCity("");
    setCurrentPage(0);
    fetchData(0, itemsPerPage); // Fetch data without any filters
    fetchMenuData();
    fetchCities();
    fetchRegions();
  };

  const fetchData = (
    pageNumber,
    size,
    keyword = undefined,
    postalCode = undefined,
    region = undefined,
    city = undefined
  ) => {
    setLoading(true);
    const query = {
      pageNumber,
      size,
      keyword,
      postalCode: postalCode,
      stateCode: region,
      cityName: city,
    };
    CityConfigurationService.getAPI(query)
      .then((res) => {
        const results = res?.data?.response?.cityFieldTechnicianConfig || [];
        setData(results);
        setTotalItems(res?.data?.response?.totalItems || 0);
        if (results.length === 0 && keyword && keyword.length > 0) {
          setMessage(label.noDataMessage);
          setSeverity("info");
          setOpenAlert(true);
        }
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
        setData([]);
        setTotalItems(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchMenuData = () => {
    setLoading(true);
    CityConfigurationService.getAPI({
      pageNumber: currentPage,
      size: 50,
    })
      .then((res) => {
        // const menuItems =
        //   res?.data?.response?.cityFieldTechnicianConfig?.map(
        //     (e) => e.localityName
        //   ) || [];
        const menuItems =
          res?.data?.response?.cityFieldTechnicianConfig
            ?.filter((e) => e.useThisRecord === true) // Filter only the records where useThisRecord is true
            .map((e) => e.localityName) || [];
        setMenu(menuItems);
        // set totalitems
        // setTotalItems(res?.data?.response?.totalItems || []);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPostalCodes = (
    pageNumber,
    size,
    keyword = undefined,
    postalCode
  ) => {
    const query = {
      pageNumber,
      size,
      keyword,
      postalcode: postalCode,
      // stateCode: region,
      // cityName: city,
    };
    // Call your service to fetch postal codes based on the keyword
    CityConfigurationService.getAPI(query)
      .then((res) => {
        setPostalCodes(
          res?.data?.response?.cityFieldTechnicianConfig?.map(
            (e) => e.postalCode
          ) || []
        ); // Update the postalCodes state
        setTotalItems(res?.data?.response?.totalItems || 0);
      })
      .catch((error) => {
        setMessage(error?.response?.data?.message || error?.message);
        setSeverity("error");
        setOpenAlert(true);
        setTotalItems(0);
      });
  };

  const handleShowUsedRecords = () => {
    setShowUsedRecords(!showUsedRecords);
    fetchData(0, itemsPerPage, searchKeyword, postalCode, region, city);
  };

  const paginate = (pageNumber, itemsPerPage) => {
    setCurrentPage(pageNumber - 1);
    setItemsPerPage(itemsPerPage);
    fetchData(pageNumber - 1, itemsPerPage);
  };

  const debouncedCitySearch = useCallback(
    debounce((value) => {
      fetchData(0, itemsPerPage, searchKeyword, postalCode, region, value);
    }, 300),
    [itemsPerPage, searchKeyword, postalCode, region]
  );

  const debouncedRegionSearch = useCallback(
    debounce((value) => {
      fetchData(0, itemsPerPage, searchKeyword, postalCode, value, city);
    }, 300),
    [itemsPerPage, searchKeyword, postalCode, city]
  );

  return (
    <Layout
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      paginate={paginate}
      currentPage={currentPage}
      title={t("cityConfiguration.pageTitle")}
      background="#ffffff"
      useNewPagination={true}
    >
      <Grid
        container
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          p: 2,
          maxWidth: "100%",
          margin: "auto",
          paddingBottom: "8px",
        }}
      >
        <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <Typography variant="h8" display="flex">
            <ArrowDropDownIcon />
            {t("filtersLable")}
          </Typography>
        </Grid>


        {/* City Search Field */}
        <Grid item xs={3} style={{ paddingLeft: "0px" }}>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              value={city}
              onChange={handleCityChange}
              onInputChange={(event, newValue) => {
                setCity(newValue);
                debouncedCitySearch(newValue);
              }}
              options={cityOptions || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("city")}
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={iconSearch}
                          alt="search"
                          style={{
                            width: "32px",
                            height: "32px",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* City Field */}
        {/* <Grid item xs={3}>
          <Autocomplete
            disablePortal
            options={cityOptions || []} // Now this is an array of city names
            onChange={(event, newValue) => handleCityChange(event, newValue)}
            renderInput={(params) => (
              <TextField {...params} label="City" fullWidth />
            )}
          />
          </Grid> */}

        <Grid item xs={3}>
          <Autocomplete
            disablePortal
            options={postalCodes}
            onChange={(event, newValue) =>
              handlePostalCodeSearchChange(event, newValue)
            }
            onInputChange={handlePostalCodeSearchChange}
            getOptionLabel={(option) => option.toString()} // Convert number to string
            renderInput={(params) => (
              <TextField {...params} label={t("postalCode")} fullWidth />
            )}
          />
        </Grid>

        {/* Region Field */}
        <Grid item xs={3}>
          <Autocomplete
            disablePortal
            options={regionOptions || []}
            value={region}
            onChange={handleRegionChange}
            onInputChange={(event, newValue) => {
              setRegion(newValue);
              debouncedRegionSearch(newValue);
            }}
            clearOnBlur={true}
            clearIcon={region ? undefined : null}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("region")}
                placeholder={t("selectoption")}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  placeholder: t("selectoption")
                }}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={3}
          style={{
            paddingTop: "12px",
            paddingLeft: "20px",
            paddingRight: "0px",
          }}
        >
          <Typography variant="subtitle2">
            <Checkbox
              checked={showUsedRecords}
              onChange={handleShowUsedRecords}
              color="primary"
            />
            {t("usedRecords")}
          </Typography>
        </Grid>

        {/* Blank grid to add some height */}
        <Grid xs={12} sx={{ height: "20px" }}></Grid>
      </Grid>

      {/* Table Section */}
      {(() => {
        if (loading) {
          return (
            <Typography variant="h6" align="center">
              {t("loading")}
            </Typography>
          );
        } else if (totalItems > 0) {
          if (showUsedRecords) {
            const usedRecords = data.filter((row) => row.useThisRecord);
            if (usedRecords.length > 0) {
              return <LocalityConfigurationTable TableData={usedRecords} />;
            } else {
              return (
                <Typography variant="h6" align="center">
                         {t("cityConfiguration.noUsedRecordsFound")}

                  {region ?  t("cityConfiguration.forRegion") : ""}.
                </Typography>
              );
            }
          } else {
            return <LocalityConfigurationTable TableData={data} />;
          }
        } else {
          return (
            <Typography variant="h6" align="center">
              {/* No records found{region ? " for the selected region" : ""}. */}
              {t("noDataAvailable")}
            </Typography>
          );
        }
      })()}
    </Layout>
  );
};

export default GetLocalityConfiguration;
