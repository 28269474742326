import React, { useState, useContext, useEffect } from "react";
import {
  Autocomplete,
  FormControl,
  Grid,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Divider from "components/common/GrowingDivider";
import Layout from "components/structure/Layout";
import { useTranslation } from "react-i18next";
import PaymentPolicyLimitsDistributionTable from "./PaymentPolicyLimitsDistributionTable";
import PaymentPolicyLimitsDistributionService from "services/PaymentPolicyLimitsDistributionService";
import { SnackbarContext } from "context/snackBarContext";
import SearchIcon from "../../../../assets/SearchIcon.svg";

const PaymentPolicyLimitsDistribution = () => {
  const { t } = useTranslation();
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  const [cropSeason, setCropSeason] = useState(null);
  const [cropSeasonOptions, setCropSeasonOptions] = useState([]);
  const [hierarchicalNode, setHierarchicalNode] = useState(null);
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hierarchicalNodeId, setHierarchicalNodeId] = useState("");

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      try {
        const [cropSeasonResponse, hierarchyResponse] = await Promise.all([
          PaymentPolicyLimitsDistributionService.getCropSeasonAPI(),
          PaymentPolicyLimitsDistributionService.getHierarchyNodeAPI(),
        ]);

        setCropSeasonOptions(
          cropSeasonResponse.data.response.map((item) => ({
            label: item.cropYear.toString(),
            value: item.cropYearUid,
          }))
        );

        const flattenHierarchy = (node, result = [], level = 0) => {
          const idValue = node.id;
          result.push({
            label: node.locationName,
            value: idValue,
            levelName: node.levelName,
            level,
          });
          if (node.childHierarchies) {
            node.childHierarchies.forEach((child) =>
              flattenHierarchy(child, result, level + 1)
            );
          }
          return result;
        };

        setHierarchyOptions(flattenHierarchy(hierarchyResponse.data.response));
      } catch (error) {
        console.error("Error fetching initial data:", error);
        setMessage("Failed to fetch initial data.");
        setSeverity("error");
        setOpenAlert(true);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [setMessage, setSeverity, setOpenAlert]);

  const renderPaymentPolicyLimitsDistributionTable = () => {
    if (!cropSeason || !hierarchicalNode) {
      return null;
    }

    return (
      <>
        <Divider
          title={t(
            "paymentPolicyLimits.subordinateHierarchiesDistributionLimit"
          )}
        />
        <PaymentPolicyLimitsDistributionTable
          cropYearUUID={cropSeason.value}
          importHierarchyId={hierarchicalNode}
        />
      </>
    );
  };

  const handleHierarchyChange = (event, newValue) => {
    if (newValue) {
      setHierarchicalNode(newValue.value); // Update with the selected value
      setHierarchicalNodeId(newValue.value); // Update ID if needed
    } else {
      setHierarchicalNode(null); // Reset state if no value is selected
      setHierarchicalNodeId(""); // Reset ID if needed
    }
  };

  return (
    <Layout title={t("paymentPolicyLimits.pageTitle")} background="#ffffff">
      <Grid
        container
        spacing={2}
        sx={{
          background: "#EFEFEF",
          borderRadius: "5px",
          p: 2,
          margin: "auto",
          marginBottom: "12px",
          pt: 0,
          pl: 0,
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h7" display="flex" alignItems="center">
            <ArrowDropDownIcon />
            {t("paymentPolicyLimits.mandatoryFilters")}
          </Typography>
        </Grid>

        {/* Crop Season Dropdown */}
        <Grid item xs={4}>
          <FormControl fullWidth>
            <Autocomplete
              value={cropSeason}
              onChange={(event, newValue) => setCropSeason(newValue)}
              options={cropSeasonOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("paymentPolicyLimits.csSearchField")}
                  placeholder={t("Select an Option")}
                  required
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* Hierarchical Node Dropdown */}
        <Grid item xs={4}>
          <FormControl fullWidth>
            <Autocomplete
              value={
                hierarchyOptions.find(
                  (option) => option.value === hierarchicalNode
                ) || null
              }
              onChange={handleHierarchyChange}
              options={hierarchyOptions}
              renderOption={(props, option) => {
                const isSelected = option.value === hierarchicalNode; // Check if the option is selected
                return (
                  <li
                    {...props}
                    style={{
                      borderLeft: isSelected ? "5px solid #004F9F" : "none", // Add blue left border for selected
                      paddingLeft: isSelected ? "12px" : "16px", // Adjust padding for consistency
                      maxWidth: isSelected ? "90%" : "100%", // Shorten width for selected
                      boxSizing: "border-box", // Ensure proper spacing and layout
                      background: isSelected ? "none" : "inherit", // Remove background for the selected option
                      "&:hover": {
                        background: "#f0f0f0", // Optional: Add hover effect
                      },
                    }}
                  >
                    <div>
                      <div
                        style={{
                          fontSize: "16px",
                        }}
                      >
                        {option.label}
                      </div>
                      <div style={{ fontSize: "10px", color: "#5A646E" }}>
                        {option.levelName}
                      </div>
                    </div>
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("paymentPolicyLimits.hnSearchField")}
                  fullWidth
                  placeholder="Select an Option"
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      opacity: 1,
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={SearchIcon}
                          alt="search"
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "#737D86",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>

      {/* Conditional Table Rendering */}
      {renderPaymentPolicyLimitsDistributionTable()}
    </Layout>
  );
};

export default PaymentPolicyLimitsDistribution;
