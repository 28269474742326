import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Select,
  Checkbox,
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
  OutlinedInput,
  InputLabel,
  ListItemText,
  FormHelperText,
  Chip,
  IconButton,
} from "@mui/material";
import Layout from "components/structure/Layout";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import { Height, Label } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import RedSwitch from "utils/RedSwitch";
import arrowLeft from "assets/arrowLeft.svg";
import { expensesTypeSchema } from "utils/validations";
import { SnackbarContext } from "context/snackBarContext";
import OccurrenceTypeService from "services/OccurrenceTypeService";
import Lable from "utils/lables.json";
import ByteSize_Upload from "assets/bytesize_upload.svg";

import Climate_Icon from "assets/climate_icon.svg";
import Column from "utils/Column";
import _ButtonDelete_ from "assets/_ButtonDelete_.svg";
import { getBase64 } from "../../../../utils/CommonFunction";
import UserService from "services/UserService";
import ExpensesTypeService from "services/ExpensesTypeService";
import Save from "assets/SaveNew.svg";
import * as Yup from "yup";
import {
  ADD_SUCCESS_MSG,
} from 'utils/AgriErrorMsg';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddExpenses3rdPartyGroup = () => {
  const [open, setOpen] = useState(false); // Modal state
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteField, setDeleteField] = useState();
  const [personName, setPersonName] = React.useState([]);

  const handleChanges = (event) => {
    let val = event.target.value;
    console.log("valtobacco", val);
    // setPersonName(typeof val === "string" ? val.split(",") : val);
    setPersonName(val);
  };
  const navigate = useNavigate();

  const location = useLocation();

  const {
    ewtpExpenseswith3rdPartiesDescription,
    tobaccoType,
    cultureName,
    edit,
    details,
    ewtpExpenseswith3rdPartiesId,
    isActive,
    tobaccoTypeId,
    investements,
  } = location.state || {};

  console.log("location state expenses", location.state);

  const [values, setValues] = useState({
    expensesDescription: ewtpExpenseswith3rdPartiesDescription || "",
    tobaccoType: tobaccoTypeId || [],
    status: isActive,
    investmentBoxes: investements || [],
  });
  const { setOpenAlert, setSeverity, setMessage } = useContext(SnackbarContext);

  console.log("tobacco name print", values.tobaccoType);

  const [errors, setErrors] = useState({});
  console.log("Expenses type errors", errors);
  const [loading, setLoading] = useState(false);
  const [deletedFunctionalities, setDeletedFunctionalities] = useState([]);
  const [tobaccoCompleteIds, setTobaccoCompleteIds] = useState([]);
  const [newMode, setNewMode] = useState(true);

  // const [boxes, setBoxes] = useState([]);

  const [tobaccoGroups, setTobaccoGroups] = useState([]);
  const [investmentList, setInventmentsList] = useState([]);

  // const [investments, setInvestment] = useState("");

  const handleAddInvestments = () => {
    setValues((prevValues) => ({
      ...prevValues,
      investmentBoxes: [
        ...prevValues.investmentBoxes,
        {
          id: Date.now(),
          value: "",
          isDelete: false,
          isNew: true,
          show: true
        },
      ],
    }));
  };

  const handleDeleteOptions = (index) => {
    setValues((prevValues) => {
      const updatedInvestmentFields = [...prevValues.investmentBoxes];
      updatedInvestmentFields.splice(index, 1);
      return {
        ...prevValues,
        investmentBoxes: updatedInvestmentFields,
      };
    });
  };

  const handleChangeInvestment = (index, event) => {
    const { name, value, type, checked } = event.target;
    console.log(
      "name, value, type, checked",
      name,
      value,
      type,
      checked,
      index
    );
    setValues((prevValues) => {
      console.log("prevValues", prevValues);
      const updatedInvestmentFields = [...prevValues.investmentBoxes];
      updatedInvestmentFields[index] = {
        ...updatedInvestmentFields[index],
        ["inInvestmentId"]: value,
      };
      return {
        ...prevValues,
        investmentBoxes: updatedInvestmentFields,
      };
    });

    console.log("investment change values", values);
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    console.log("ONCHANGEDESC", name, ",", value, ",", type, ",", checked)
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleClear = (field) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: "",
    }));
  };

  const handleSubmit = async (event) => {
    console.log("handl submit");

    let tobaccoIDs = [];
    personName.forEach((name) => {
      console.log("element:", name);
      tobaccoGroups.forEach((all) => {
        console.log("all:::", all);
        let newString = all.cultureType + "-" + all.tobaccoGroup;
        if (name == newString) {
          let obj = {
            tobaccoTypeId: all.id,
            isDelete: false,
          };
          tobaccoIDs.push(obj);
        }
      });
    });

    console.log("tobaccoIDs", tobaccoIDs);
    const updatedValues = {
      ...values,
      tobaccoType: personName,
    };

    event.preventDefault();
    // let schemaValidationErrors = {};
    try {
      console.log("updatedValues", updatedValues);

      await expensesTypeSchema.validate(updatedValues, { abortEarly: false });

      // console.log("schemaValidationErrors", schemaValidationErrors);
      console.log("pkl", personName);
      let arr = [];
      updatedValues.investmentBoxes.forEach(function (investment) {
        if (investment.value != "") {
          arr.push(investment);
        }
      });
      //     if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        createdByUser: "abc",
        modifiedByUser: "abc",
        ewtpExpenseswith3rdPartiesDescription:
          updatedValues.expensesDescription,
        tobaccoTypes: tobaccoIDs,
        isActive: updatedValues.status || 0,
        //        countryId: "5D68BC83-3635-4A2B-A0DF-1199B0B75C6E",
        //        languageId: "960D7994-5B31-4E5B-8D05-C6D58D918229",
        // countryId: "06fde653-a2c4-43b7-879f-4525039d9148",
        // languageId: "0a31e178-fdf9-4c8c-bd7b-447a788eada5",
        countryId: "",
        languageId: "",
        investments: arr.map((item) => ({
          inInvestmentId: item.value,
          isDelete: item.isDelete,
        })),
      };
      console.log("expense type payload", payload);
      //  try {
      setLoading(true);
      const response = await ExpensesTypeService.createExpenseswith3rdParty({
        ...payload,
      });
      if (response.status === 201 || response.status === 200) {
        setSeverity("success");
        setMessage(ADD_SUCCESS_MSG);
        setOpenAlert(true);
        navigate("/expenses3rdPartyList");
        setLoading(false);
      } else if (response.status === 409) {
        setSeverity("error");
        setMessage(response?.data?.message);
        setOpenAlert(true);
        setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage("Submission failed");
        setOpenAlert(true);
        setLoading(false);
      }
      // } catch (error) {
      //   setLoading(false);
      //   // if (error.response && error.response.status === 409) {
      //   //   // console.log("error",error.response.data.message)
      //   //   setSeverity("error");
      //   //   setMessage(error.response.data.message);
      //   //   setOpenAlert(true);
      //   //   // navigate("/cultureRegistration");
      //   // } else {
      //   console.error("Failed to submit form");
      //   setSeverity("error");
      //   setMessage(error.response.data.message);
      //   setOpenAlert(true);
      //   // }
      // }
      //   }
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage("An error occurred while submitting the form");
        setOpenAlert(true);
      }
      // err?.inner?.forEach((error) => {
      //   schemaValidationErrors[error.path] = error.message;
      // });
      // setErrors(schemaValidationErrors);
      // console.log("error", schemaValidationErrors);
    }
    // }
  };

  console.log("VALUESVALIDEXP", values)

  const handleUpdate = async (event) => {
    console.log("handleUpdate functin");
    //event.preventDefault();

    let tobaccoIDs = [];
    personName.forEach((name) => {
      tobaccoGroups.forEach((all) => {
        console.log("allallall", all);

        let newString = all.cultureType + "-" + all.tobaccoGroup;
        if (name == newString) {
          let obj = {
            tobaccoTypeId: all.id,
            isDelete: false,
          };
          tobaccoIDs.push(obj);
        }
      });
    });

    let tempTobacco = [];

    tobaccoIDs.forEach((element) => {
      let anythingFound = false;
      tobaccoCompleteIds.forEach((eleT) => {
        if (element.tobaccoTypeId == eleT.tobaccoTypeId) {
          anythingFound = true;
          let obj = {
            tobaccoTypeId: element.tobaccoTypeId,
            isDelete: false,
            tptThirdPartiesTobbacoId: eleT.tptThirdPartiesTobbacoId,
          };
          tempTobacco.push(obj);
        }
      });
      if (anythingFound == false) {
        let obj = {
          tobaccoTypeId: element.tobaccoTypeId,
          isDelete: false,
        };
        tempTobacco.push(obj);
      }
    });
    console.log("tempTobaccdhdvddo::", tempTobacco);

    const filteredArray = tobaccoCompleteIds.filter(
      (itemA) =>
        !tempTobacco.some(
          (itemB) => itemB.tobaccoTypeId === itemA.tobaccoTypeId
        )
    );

    console.log("filteredArray:::", filteredArray);
    if (filteredArray.length > 0) {
      let excludeArray = [];
      filteredArray.forEach((element) => {
        let obj1 = {
          isDelete: true,
          tobaccoTypeId: element.tobaccoTypeId,
          tptThirdPartiesTobbacoId: element.tptThirdPartiesTobbacoId,
        };
        tempTobacco.push(obj1);
      });
    }

    console.log("djdbjdbdjbdjb complete", tempTobacco);

    const updatedValues = {
      ...values,
      tobaccoType: personName,
    };

    //let schemaValidationErrors = {};
    event.preventDefault();
    try {
      await expensesTypeSchema.validate(updatedValues, { abortEarly: false });
      // } catch (err) {
      //   err?.inner?.forEach((error) => {
      //     schemaValidationErrors[error.path] = error.message;
      //   });
      //   setErrors(schemaValidationErrors);
      //   console.log("schemaValidationErrors", err);
      // }
      console.log("Values", updatedValues);
      // try {
      //   console.log("errorVal123",)
      //   await expensesTypeSchema.validate(updatedValues, { abortEarly: false });
      // } catch (err) {
      //   err?.inner?.forEach((error) => {
      //     schemaValidationErrors[error.path] = error.message;
      //   });
      //   setErrors(schemaValidationErrors);
      // }
      //      console.log("errorVal", schemaValidationErrors);
      let arr = [];
      updatedValues.investmentBoxes.forEach(function (investment) {
        if (investment.isNew) {
          if (investment.value != "") {
            arr.push(investment);
          }
        }
        else {
          arr.push(investment);
        }

      });

      // if (Object.keys(schemaValidationErrors).length === 0) {
      const payload = {
        ewtpExpenseswith3rdPartiesId: ewtpExpenseswith3rdPartiesId,
        createdByUser: "abc",
        modifiedByUser: "abc",
        ewtpExpenseswith3rdPartiesDescription: updatedValues.expensesDescription,
        tobaccoTypes: tempTobacco,
        isActive: updatedValues.status || 0,
        //  countryId: "5D68BC83-3635-4A2B-A0DF-1199B0B75C6E",
        //       countryId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        // countryId: "06fde653-a2c4-43b7-879f-4525039d9148",
        // languageId: "0a31e178-fdf9-4c8c-bd7b-447a788eada5",
        countryId: "",
        languageId: "",
        //        languageId: "960D7994-5B31-4E5B-8D05-C6D58D918229",
        // investements: updatedValues.investmentBoxes.map((item) => ({
        //   inInvestmentId: item.inInvestmentId,
        //   isDelete: false,
        //   tpgiThirdPartiesInvestmentId: item.tpgiThirdPartiesInvestmentId,
        // })),
        investments: arr.map((item) => (
          item.isNew
            ? {
              inInvestmentId: item.value,
              isDelete: item.isDelete,
            } :
            {
              inInvestmentId: item.orgValue,
              tpgiThirdPartiesInvestmentId: item.id,
              //     igInvstgroupinvstId: item.groupInvestId,
              isDelete: item.isDelete,
            }
        )),
      };
      console.log("PAYLOAD update", payload)
      //  try {
      setLoading(true);
      const response = await ExpensesTypeService.createExpenseswith3rdParty({
        ...payload,
      });
      if (response.status === 200 || response.status === 201) {
        setSeverity("success");
        setMessage("Updated successfully");
        setOpenAlert(true);
        navigate("/expenses3rdPartyList");
        setLoading(false);
      } else if (response.status === 409) {
        setSeverity("error");
        setMessage(response?.data?.message);
        setOpenAlert(true);
        setLoading(false);
      } else {
        console.error("Failed to submit form");
        setSeverity("error");
        setMessage("Submission failed");
        setOpenAlert(true);
        setLoading(false);
      }
      //    }
      // catch (error) {
      //   console.log("coming to catch block");
      //   setLoading(false);
      //   if (error.response) {
      //     const errorMessage = error.response.data.message;
      //     setMessage(errorMessage);
      //     setOpenAlert(true);
      //   } else {
      //     console.error("Failed to submit form");
      //     setSeverity("error");
      //     setMessage("Update failed");
      //     setOpenAlert(true);
      //   }
      // }
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        let newError = {};
        err?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setErrors(newError);
      } else {
        console.error("An error occurred while submitting the form:", err);
        setSeverity("error");
        setMessage("An error occurred while submitting the form");
        setOpenAlert(true);
      }
      // err?.inner?.forEach((error) => {
      //   schemaValidationErrors[error.path] = error.message;
      // });
      // setErrors(schemaValidationErrors);
      // console.log("error", schemaValidationErrors);
    }
    // }
  };
  const handleCancelClick = () => {
    setOpen(true); // Open the confirmation modal
  };

  const handleClose = () => {
    setOpen(false); // Close the modal without deleting
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false); // Close the modal without deleting
  };

  useEffect(() => {
    ExpensesTypeService.getTobbacoType()
      .then((response) => {
        setTobaccoGroups(response.data.response);
      })
      .catch((error) => console.error("Error fetching data:", error));
    if (edit != undefined) {
      setNewMode(false);
      console.log("location?.state::", location?.state);
      let tempArray = [];
      setTobaccoCompleteIds(location?.state?.tobaccoTypeId);
      location?.state?.tobaccoTypeId.forEach((element) => {
        console.log("element::", element);
        tempArray.push(element.tobaccoType + "-" + element.cultureName);
      });

      setPersonName(
        // On autofill we get a stringified value.
        typeof tempArray === "string" ? tempArray.split(",") : tempArray
      );

      // setTobaccoGroups(tempArray)
    }
  }, []);

  useEffect(() => {
    ExpensesTypeService.getInvestments()
      .then((response) => {
        setInventmentsList(response.data.response);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    if (location.state) {
      const {
        greenManureIdEdit,
        statusEdit,
        createdByUserEdit,
        modifiedByUserEdit,
        greenManureNameEdit,
        investmentsEdit,
        tobaccoTypeEdit,
        details,
        tobaccoTypeId,
        investements,
      } = location.state;
      console.log("🚀 ~ useEffect ~ unitOfMeasure:", location.state);

      setValues((prevValues) => ({
        ...prevValues,
        tobaccoType: tobaccoTypeId,
      }));

      if (Array.isArray(investements)) {
        const updatedFields = investements.map((investment) => ({
          id: investment.tpgiThirdPartiesInvestmentId, // Use a unique identifier
          value: investment.inInvestmentId || "", // Initialize with the investment name
          isDelete: investment.isDelete,
          isNew: false,
          show: true,
          orgValue: investment.inInvestmentId || "",
        }));
        setValues((prevValues) => ({
          ...prevValues,
          investmentBoxes: updatedFields
        }))
      }
      // setValues((prevValues) => ({
      //   investements: Array.isArray(investements)
      //     ? investements.map((investment) => ({
      //       id: investment.tpgiThirdPartiesInvestmentId,
      //       value: investment.inInvestmentId || "",
      //       isDelete: investment.isDelete,
      //       isNew: false,
      //       show: true,
      //       orgValue: investment.inInvestmentId || "",
      //     }))
      //     : [],
      // }));

      console.log("updated values", values.investmentBoxes);
    }
  }, [location.state]);

  console.log("EDITEXPENSE", values.investmentBoxes)


  const handleDeleteField = (field) => {
    setValues((prevValues) => ({
      ...prevValues,
      investmentBoxes: field.isNew
        ? prevValues.investmentBoxes.filter((f) => f.id !== field.id)
        : prevValues.investmentBoxes.map((oldField) =>
          oldField.id === field.id
            ? { ...oldField, isDelete: true, show: false }
            : oldField
        ),
    }));
    setDeleteOpen(false)
  };

  const handleChangeNewField = (id, newValue, isNew) => {
    setValues((prevValues) => ({
      ...prevValues,
      investmentBoxes: prevValues.investmentBoxes.map((field) =>
        field.id === id
          ? {
            ...field,
            value: newValue,
            ...(isNew
              ? {}
              : {
                orgValue: newValue !== "" ? newValue : field.value,
                isDelete: newValue === "",
              }),
          }
          : field
      ),
    }));
  };

  const handleChipDelete = (valueToRemove) => {
    setPersonName((prev) => prev.filter((value) => value !== valueToRemove));
  };
  return (
    <Layout
      title={"Expenses with 3rd Parties Group Registration"}
      background="#ffffff"
    >
      <form
        autoComplete="off"
        noValidate
        style={{ paddingBottom: "150px" }}
        onSubmit={handleSubmit}
      >
        <Box display="" alignItems="center">
          <PaperContainer background="#EFEFEF">
            <Box sx={{ display: "flex", paddingBottom: "1em" }}>
              <ArrowDropDownIcon sx={{ marginRight: 1 }} />
              <Typography>{Lable.fromLable}</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  name="expensesDescription"
                  label="Expenses with 3rd Parties Group Description"
                  required
                  error={!!errors.expensesDescription}
                  helperText={errors.expensesDescription}
                  value={values.expensesDescription}
                  onChange={handleChange}
                  disabled={!newMode && !edit}
                  placeholder="-"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: "100%",
                    // height: "46px",
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-disabled": {
                        "& fieldset": {
                          borderColor: "gray",
                          borderWidth: "1px"
                        },
                        "&:hover fieldset": {
                          borderColor: "gray",
                          borderWidth: "1px"
                        },
                      },
                    },
                    '& .MuiInputBase-input::placeholder': {
                      opacity: 1,
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-disabled": {
                        color: "#102a62",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                {/* <FormControl
                  sx={{ width: "100%" }}
                  error={!!errors.tobaccoType}
                >
                  <InputLabel
                    id="Tobacco Type"
                    sx={{
                      color: "#112a63",
                      "&.Mui-focused": { color: "#112a63" },
                    }} // Change color here
                    shrink={true}
                  >
                    Tobacco Type
                    <span style={{ color: "red" }}> *</span>
                  </InputLabel>
                  <Select
                    labelId="Tobacco Type"
                    id="demo-multiple-checkbox"
                    multiple
                    //  / name={searchParams.tobaccoType}
                    value={personName}
                    required
                    //  error={!!errors.tobaccoIDs}
                    // helperText={errors.tobaccoIDs}
                    onChange={handleChanges}
                    input={<OutlinedInput label="Tobacco Type" />}
                    //                    renderValue={(selected) => selected.join(",")}
                    renderValue={(selected) => selected.length ? selected.join(", ") : "Select an option"}
                    MenuProps={MenuProps}
                    variant="outlined"
                    displayEmpty
                    disabled={!newMode && !edit}
                    sx={{
                      // height: "55px",
                      "&.Mui-disabled fieldset": {
                        borderColor: "gray !important",
                        borderWidth: "1px !important",
                      },
                      "&.Mui-disabled:hover fieldset": {
                        borderColor: "gray",
                        borderWidth: "1px",
                      },
                    }}
                    SelectProps={{
                      IconComponent: ArrowDropDownIcon,
                      endAdornment: (
                        <CloseIcon
                          onClick={() => handleClear("tobaccoType")}
                          style={{
                            width: "18px",
                            cursor: "pointer",
                            marginRight: "12px",
                          }}
                        />
                      ),
                    }}
                  >
                    {tobaccoGroups.map((tobacco, index) => (
                      <MenuItem
                        key={index}
                        value={tobacco.cultureType + "-" + tobacco.tobaccoGroup}
                      >
                        <Checkbox
                          checked={personName.includes(
                            tobacco.cultureType + "-" + tobacco.tobaccoGroup
                          )}
                        />
                        <ListItemText
                          primary={
                            tobacco.cultureType + "-" + tobacco.tobaccoGroup
                          }
                        />
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.tobaccoType && (
                    <FormHelperText>{errors.tobaccoType}</FormHelperText>
                  )}
                </FormControl> */}
                <FormControl
                  sx={{ width: "100%" }}
                  error={!!errors.tobaccoType}
                >
                  <InputLabel
                    id="Tobacco Type"
                    sx={{
                      color: "#112a63",
                      "&.Mui-focused": { color: "#112a63" },
                    }}
                    shrink={true}
                  >
                    Tobacco Type
                    <span style={{ color: "red" }}> *</span>
                  </InputLabel>
                  <Select
                    labelId="Tobacco Type"
                    id="demo-multiple-checkbox"
                    multiple
                    isMulti
                    value={personName}
                    required
                    onChange={handleChanges}
                    input={<OutlinedInput label="Tobacco Type" />}
                    renderValue={(selected) => (
                      selected.length ? (
                        <div style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              disabled={!newMode && !edit}
                              label={value}
                              onDelete={() => handleChipDelete(value)}
                              deleteIcon={
                                <IconButton
                                  size="small"
                                  sx={{
                                    backgroundColor: "rgba(0, 0, 0, 0.18)",
                                    padding: "1px",
                                    "&:hover": {
                                      backgroundColor: "rgba(0, 0, 0, 0.38)", // Slightly lighter shade on hover
                                    },
                                  }}
                                >
                                  <CloseIcon
                                    fontSize="small"
                                    sx={{ fontSize: "19px" }}
                                    onMouseDown={(event) => event.stopPropagation()}
                                  />
                                </IconButton>
                              }
                              sx={{
                                "&.Mui-disabled": {
                                  opacity: 1,
                                  backgroundColor: "#e0e0e0",
                                  color: "#333333",
                                },
                              }}
                            />
                          ))}
                        </div>
                      ) : "Select an option"
                    )}
                    MenuProps={MenuProps}
                    variant="outlined"
                    displayEmpty
                    disabled={!newMode && !edit}
                    sx={{
                      "&.Mui-disabled fieldset": {
                        borderColor: "gray !important",
                        borderWidth: "1px !important",
                      },
                      "&.Mui-disabled:hover fieldset": {
                        borderColor: "gray",
                        borderWidth: "1px",
                      },
                    }}
                  >
                    {tobaccoGroups.map((tobacco, index) => (
                      <MenuItem
                        key={index}
                        value={`${tobacco.cultureType}-${tobacco.tobaccoGroup}`}
                        sx={{
                          borderLeft: personName.includes(`${tobacco.cultureType}-${tobacco.tobaccoGroup}`)
                            ? "4px solid #004F9F" // Blue line for selected items
                            : "none",
                          paddingLeft: personName.includes(`${tobacco.cultureType}-${tobacco.tobaccoGroup}`)
                            ? "8px" // Adjust padding to align content after border
                            : "12px",
                        }}
                      >
                        {/* <Checkbox
                          checked={personName.includes(
                            `${tobacco.cultureType}-${tobacco.tobaccoGroup}`
                          )}
                        /> */}
                        <ListItemText
                          primary={`${tobacco.cultureType}-${tobacco.tobaccoGroup}`}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.tobaccoType && (
                    <FormHelperText>{errors.tobaccoType}</FormHelperText>
                  )}
                </FormControl>

                {/* <FormControl>
                  <TextField
                    select
                    required
                    name="tobaccoType"
                    label={"Tobacco Type"}
                    error={!!errors.tobaccoType}
                    helperText={errors.tobaccoType}
                    value={values.tobaccoType}
                    onChange={(e) => handleChange(e)}
                    variant="outlined"
                    sx={
                      {width : "420px",
                        height:"46px"
                      }   
                   }
                    SelectProps={{
                      IconComponent: ArrowDropDownIcon,
                      endAdornment: (
                        <CloseIcon
                          onClick={() => handleClear("tobaccoType")}
                          style={{
                            width:"18px",
                            cursor: "pointer",
                            marginRight: "12px",
                          }}
                        />
                      ),
                    }}
                  >

                    {tobaccoGroups.map((item,index) => (
                      <MenuItem key={index} value={item.id}>
                       {item.cultureType+"-"+item.tobaccoGroup}
                      </MenuItem>
                    ))}

                  </TextField>
                </FormControl> */}
              </Grid>

              <Box
                sx={{
                  ml: "15px",
                  mt: "15px",
                  display: "flex",
                  // alignItems: "center",
                  gap: "1rem",
                }}
              >
                <FormControl component="fieldset">
                  <FormLabel
                    sx={{
                      color: "#5A646E",
                      mb: 1,
                      ml: 0.5,
                      "&.Mui-focused": { color: "#5A646E" },
                      "&:hover": { color: "#5A646E" },
                      fontWeight: "500",
                    }}
                  >
                    Status
                    <span style={{ color: "red" }}> *</span>
                  </FormLabel>

                  <FormControlLabel
                    sx={{
                      ml: 0.5,
                      display: "flex",
                      alignItems: "center",
                      gap: "0.4rem",
                    }}
                    control={
                      <RedSwitch
                        checked={values.status}
                        onChange={handleChange}
                        name="status"
                        color="primary"
                        disabled={!newMode && !edit}
                      />
                    }
                    labelPlacement="start"
                    label={
                      <Box sx={{ width: "3rem", mr: "5px", fontWeight: "500", color: "#737D86" }}>
                        {values?.status === true ? "Active" : "Inactive"}
                      </Box>
                    }
                  />
                  {/* 
                  <FormLabel
                    sx={{
                      ml: 4,
                      mb: 0,
                      display: "flex",
                    }}
                  >
                    Active
                  </FormLabel> */}
                </FormControl>
              </Box>
            </Grid>
          </PaperContainer>

          <Box>
            <Box
              display=""
              alignItems="center"
              flexDirection={"column"}
              mt={3}
              mb={3}
            >
              <Box display="flex" alignItems="center" flexDirection={"row"}>
                <FormLabel
                  sx={{
                    ml: 1,
                    color: "#000000",
                    display: "flex",
                  }}
                >
                  Investments
                </FormLabel>
                {!newMode && !edit ? null : (
                  <Button
                    style={{
                      position: "absolute",
                      right: 0,
                      float: "right",
                      marginLeft: "100px",
                      marginRight: "25px",
                      marginBottom: "8px",
                      background: "#004F9F",
                      alignSelf: "center",
                    }}
                    onClick={handleAddInvestments}
                    variant="contained"
                    disabled={!newMode && !edit}
                  >
                    ADD NEW
                  </Button>
                )}
              </Box>

              <hr
                style={{
                  color: "#004F9F",
                  backgroundColor: "#004F9F",
                  height: 2,
                  borderColor: "#004F9F",
                }}
              />
            </Box>

            {values.investmentBoxes.map((field) => (
              // <PaperContainer background="#EFEFEF">
              field.show && (
                <Box
                  // display="flex"
                  // justifyContent="space-between"
                  // alignItems="center"
                  // mb={2}
                  // height={"15px"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "4px",
                    boxShadow:
                      "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
                    padding: "1rem 1rem 1rem 1rem",
                    margin: "12px 0px 10px 0px",
                    background: " #EFEFEF",
                  }}
                >
                  <TextField
                    select
                    name="inInvestmentName"
                    label="Investment"
                    disabled={!newMode && !edit}
                    // value={values.inInvestmentName}
                    // value={
                    //   edit != undefined
                    //     ? box.inInvestmentId
                    //     : values.inInvestmentId
                    // }
                    value={field.value || "PL"}
                    onChange={(e) => handleChangeNewField(field.id, e.target.value, field.isNew)}
                    sx={{
                      width: "530px",
                      height: "20px",
                      marginBottom: "30px",
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-disabled": {
                          "& fieldset": {
                            borderColor: "gray",
                            borderWidth: "1px",
                          },
                          "&:hover fieldset": {
                            borderColor: "gray",
                            borderWidth: "1px",
                          },
                        },
                      },
                      "& .MuiInputLabel-root": {
                        "&.Mui-disabled": {
                          color: "#102a62",
                        },
                      },
                    }}
                    SelectProps={{
                      IconComponent: ArrowDropDownIcon,
                      // endAdornment: (
                      //   <CloseIcon
                      //     onClick={() =>
                      //       // handleClear("tobaccoType")
                      //       !details && handleChangeNewField(field.id, "", field.isNew)

                      //     }
                      //     style={{
                      //       width: "18px",
                      //       cursor: edit == false ? "default" : "pointer",
                      //       marginRight: "12px",
                      //     }}
                      //   />
                      // ),
                    }}
                  ><MenuItem value="PL" disabled sx={{ display: "none" }}>
                      Select an option
                    </MenuItem>
                    {investmentList.map((item, index) => (
                      <MenuItem key={index} value={item.inInvestmentId}>
                        {item.inInvestmentName}
                      </MenuItem>
                    ))}
                  </TextField>
                  {!newMode && !edit ? null : (
                    <Button
                      // style={{ marginTop: "20px", marginRight: "5px" }}
                      onClick={() =>
                      // handleDeleteOptions(index)
                      // handleDeleteField(field)
                      {
                        setDeleteField(field)
                        setDeleteOpen(true)
                      }
                      }
                      disabled={!newMode && !edit}
                    >
                      <div>
                        <img src={_ButtonDelete_} alt="Delete" />
                      </div>
                    </Button>
                  )}

                </Box>
              )

              // </PaperContainer>
            ))}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
                left: "25px",
                right: "25px",
                position: "absolute",
                bottom: "80px",
              }}
            >
              <Button
                style={{
                  background: "#737D86",
                }}
                // onClick={
                //   navigate("/occurrenceTypeList")
                // }
                startIcon={<img src={arrowLeft} alt="Arrow" />}
                variant="contained"
                onClick={edit == false ? () => navigate("/expenses3rdPartyList") : () => handleCancelClick()}
              >
                Cancel
              </Button>
              {!newMode && !edit ? null : (
                <Button
                  style={{
                    background: "#EF7D00",
                  }}
                  type="submit"
                  disabled={!newMode && !edit}
                  onClick={!edit ? handleSubmit : handleUpdate}
                  startIcon={<img src={Save} alt="Arrow" />}
                  variant="contained"
                >
                  {/* {!edit ? "Save " : "Update "} */}
                  Save
                </Button>
              )}

              {/* )} */}
            </Box>
          </Box>
        </Box>
      </form>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "520px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              Cancel Operation
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
              Are you sure you want to cancel this operation? All your data will
              be lost.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              No
            </Button>
            <Button
              onClick={() => navigate("/expenses3rdPartyList")}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "7px",
            width: "520px",
            margin: "auto",
            mt: 25,
            height: "200px",
          }}
        >
          <Box
            sx={{
              background: "#102A62",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontSize: "20px", fontWeight: 500 }}
            >
              Delete Confirmation
            </Typography>
          </Box>
          <Box sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontWeight: 400 }}
            >
              Are you sure you want to delete this record?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              mb: 1,
            }}
          >
            <Button
              onClick={() => handleDeleteClose()}
              sx={{
                background: "#737D86",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#737D86" },
              }}
            >
              No
            </Button>
            <Button
              onClick={() => handleDeleteField(deleteField)}
              sx={{
                background: "#004F9F",
                width: "100px",
                color: "#ffffff",
                "&:hover": { background: "#004F9F" },
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </Layout>
  );
};

const PaperContainer = styled(Paper)`
  padding: 1rem 1rem 1rem 1rem;
  margin: 12px 0px 0px 0px;
  background: ${(props) =>
    props.background || props.theme.palette.background.default};
`;

export default AddExpenses3rdPartyGroup;
